import React from "react";
import { Element } from "react-scroll";
import { graphql } from 'gatsby'

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/peel-creative/Hero";
import TextBlock from "../sections/peel-creative/TextBlock";
import Tagline from "../sections/peel-creative/Tagline";
import Work from "../sections/peel-creative/Work";
import SEO from "../components/Seo";

const IndexPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicHomepage.nodes[0].data

  return (
    <>
      <PageWrapper>
        <SEO title="Home" description="Great design is about choice. It helps customers choose one product or business over the next. " />

        <Hero carousel={document.carousel} />
        <Tagline title={document.top_text.text} color="#9fc0c5" />

        {document.copy_blocks.map((item, index) => {
          var mt = 0

          if (index == 0){
            mt=0
          }

          var pt = 5

          if (index != 0){
            pt=0
          }

          var bg = "#efefef";
          if (index % 2 == 0) {
            bg = "#efefef";
          }

          return (<TextBlock title={item.title.text} title_color={item.title_color} copy={item.copy.text} bg={bg} pb={5} mt={mt} pt={pt}/>
          );
        })}



        <Work items={document.linked_caterogies} />
      </PageWrapper>
    </>
  );
};

export default IndexPage;


export const query = graphql`
query HomePage {
  allPrismicHomepage {
    nodes {
      data {
        carousel {
          featured_image {
            localFile {
              url
              childrenImageSharp {
                fluid(quality: 75) {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
          }
          subline {
            text
          }
          headline {
            text
          }
        }
        top_text {
          text
        }
        copy_blocks {
          title_color
          title {
            text
          }
          copy {
            text
          }
        }
        linked_caterogies {
          linked_category {
            document {
              ... on PrismicWorkCategory {
                id
                uid
                data {
                  title {
                    text
                  }
                  category_name{
                    text
                  }
                  featured_works {
                    featured_work {
                      document {
                        ... on PrismicWork {
                          id
                          data {
                            title {
                              text
                            }
                            project_name {
                              text
                            }
                            hero_image {
                                localFile {
                                  url
                                  childrenImageSharp {
                                    fluid(quality: 75) {
                                      base64
                                      srcWebp
                                      srcSetWebp
                                      originalImg
                                      originalName
                                    }
                                  }
                                }
                            }
                          }
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`